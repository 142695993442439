import isEqual from "lodash/isEqual";

export function pluralize(number: number, singular: string, plural: string) {
  return number <= 1 ? singular : plural;
}

export function getTimeFromObjectID(objectID: string): Date {
  return new Date(Number.parseInt(objectID.slice(0, 8), 16) * 1000);
}

const compactNumberFormatter = new Intl.NumberFormat(undefined, {
  notation: "compact",
  maximumFractionDigits: 1,
});

export function formatCompactNumber(number: number) {
  return compactNumberFormatter.format(number);
}

/**
 *
 * @param {number} price needs to account for its decimal place before passing to this function
 * @returns {string} will not add currency symbol
 */
export function roundAndFormatPriceNumber(price: number) {
  if (price < 1000) return Math.round(price);
  return formatCompactNumber(price);
}

/**
 * Converts milliseconds to days
 */
export function msToDays(milliseconds: number) {
  return milliseconds / 86_400_000;
}

/**
 * Converts days to milliseconds
 */
export function daysToMs(days: number) {
  return days * 86_400_000;
}

/**
 * Build image URL
 * @param url full URL or path string
 * @returns full URL
 */
export function buildImageUrl(url: string) {
  if (!url) {
    return "";
  }

  const baseUrl =
    typeof document === "undefined"
      ? process.env.REACT_APP_BASE_URL
      : window.env.REACT_APP_BASE_URL;

  if (url.startsWith("http")) {
    return url;
  }

  return `${baseUrl}${url}`;
}

export function getEnumValues(enumerator: Record<string, string | number>): (string | number)[] {
  if (!enumerator) {
    return [];
  }
  return Object.keys(enumerator)
    .filter((key) => Number.isNaN(Number(key)))
    .map((key) => enumerator[key]);
}

export function isArraysStringEqual(array1: string[], array2: string[]) {
  if (array1.length !== array2.length) {
    return false;
  }
  return isEqual(new Set(array1), new Set(array2));
}

export async function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
